<template>
  <div style="border-radius: 5px; background: white">
    <div style="padding: 15px 20px">
      <span style="font-weight: bold">Quản lý doanh thu thuần</span>
    </div>
    <hr style="margin: 0; padding: 0"/>
    <div style="padding: 15px 20px">
      <div style="display: flex; align-items: end; gap: 20px">
        <div style="display: flex; flex-direction: column; gap: 10px">
          <span>Thời gian </span>
          <el-date-picker
              v-model="date"
              type="monthrange"
              style="width: 100%"
              range-separator="đến"
              start-placeholder="Tháng bắt đầu"
              format="MM-yyyy"
              value-format="yyyy-MM"
              end-placeholder="Tháng kết thúc">
          </el-date-picker>
        </div>
        <el-button
            type="success" style="background: #500DA7; color: white; border: 0"
            :disabled="is_export_excel" @click="exportExcel">
          <i class="el-icon-download" style="color: white"></i> Xuất excel
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {exportExcel} from "@/core/filters";
import {EXPORT_EXCEL_NET_REVENUE} from "@/core/services/store/payments/payments.module";

export default {
  name: 'NetRevenueExport',
  data() {
    return {
      date: [],
      is_export_excel: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý doanh thu thuần", name: 'NetRevenueExport'},
      {title: "Quản lý tài chính "}
    ]);
  },
  methods: {
    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_NET_REVENUE = [
        {
          key: 'student_name',
          value: 'Họ tên học viên',
        },
        {
          key: 'user_id',
          value: 'Mã học viên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại học viên',
        },
        {
          key: 'contract_id',
          value: 'Mã hợp đồng',
        },
        {
          key: 'lesson_number',
          value: 'Số buổi học',
        },
        {
          key: 'revenue_net',
          value: 'Doanh thu thuần',
        },
        {
          key: 'cost_difference',
          value: 'Chi phí chênh lệch tháng trước',
        },
        {
          key: 'time',
          value: 'Thời gian',
        },
        {
          key: 'center',
          value: 'Nhãn',
        },
        {
          key: 'chi_nhanh_hoc',
          value: 'Chi nhánh',
        },
        {
          key: 'id_course',
          value: 'ID khóa',
        },
        {
          key: 'course',
          value: 'Khóa học',
        },
        {
          key: 'classroom',
          value: 'Mã lớp',
        },
        {
          key: 'money_book',
          value: 'Chi phí sách',
        },
        {
          key: 'money_box',
          value: 'Chi phí box',
        },
        {
          key: 'money_admin_test',
          value: 'Chi phí admin',
        },
        {
          key: 'book_material',
          value: 'NVL Sách',
        },
        {
          key: 'certificate_material',
          value: 'NVL Chứng chỉ',
        },
        {
          key: 'output_bonus_material',
          value: 'NVL Thưởng đầu ra',
        },
        {
          key: 'party_material',
          value: 'NVL Liên hoan KG/KT',
        },
        {
          key: 'stationery_material',
          value: 'NVL Văn phòng phẩm',
        },
        {
          key: 'teacher_labor',
          value: 'NC Giáo viên',
        },
        {
          key: 'open_end_labor',
          value: 'NC Khai giảng & Kết thúc',
        },
        {
          key: 'demo_labor',
          value: 'NC Demo',
        },
        {
          key: 'end_gift_labor',
          value: 'NC Quà kết thúc',
        },
        {
          key: 'teaching_assistant_labor',
          value: 'NC Trợ giảng',
        },
        {
          key: 'book_labor',
          value: 'NC Box',
        },
        {
          key: 'contract_create',
          value: 'Thời gian tạo hợp đồng',
        },

      ]
      const payload = {
        start_date: this.date[0],
        end_date: this.date[1],
      }
      this.$store.dispatch(EXPORT_EXCEL_NET_REVENUE, payload).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_NET_REVENUE, res.data, "Báo cáo doanh thu thuần.xlsx", false)
        }
      });
    },
  }
}
</script>